$(function () {
    $('.carousel-banner').owlCarousel({
        items: 1,
        autoplay: true,
        nav: false,
        dots: false,
        loop: true
    })
    //fix owcarrusel si solo es un item
    const url = window.location.origin
    $many = 0
    $('.zp-venta-de-proyectos .zp-card-destacados').each(function () {
        $many++
    })
    $('.zp-venta-de-proyectos').owlCarousel({
        loop: $('.zp-venta-de-proyectos .zp-card-destacados').length > 3,
        rewind: $('.zp-venta-de-proyectos .zp-card-destacados').length > 3,
        margin: 1,
        responsiveClass: true,
        autoplay: true,
        navigation: true,
        nav: true,
        navText: [
            `<div class="btn control control-left text-center zp-card-carrusel-btn-right"><img src="${url}/storage/imgs/otros/flecha_slider_izq.svg" alt="img-flecha" class=w-100></div>`,
            `<div class="btn control control-right text-center zp-card-carrusel-btn-left"><img src="${url}/storage/imgs/otros/flecha_slider_der.svg" alt="img-flecha" class=w-100></div>`
        ],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 3
            }
        }
    })

    if ($('.zp-venta-de-proyectos .zp-card-destacados').length < 4) {
        $('.zp-venta-de-proyectos .owl-nav .control-right').hide()
        $('.zp-venta-de-proyectos .owl-nav .control-left').hide()
    }

    $many = 0
    $('.zp-inmobiliarias .zp-inmobiliaria').each(function () {
        $many++
    })
    $('.zp-inmobiliarias').owlCarousel({
        loop: $('.zp-inmobiliarias .zp-inmobiliaria').length > 4,
        rewind: $('.zp-inmobiliarias .zp-inmobiliaria').length > 4,
        margin: 1,
        responsiveClass: true,
        autoplay: true,
        navigation: false,
        dots: false,
        nav: false,
        navText: '',
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            992: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    })
})
